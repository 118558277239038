<template>
  <div class="container" ref="wrapper">
    <van-sticky>
      <van-nav-bar
        title="我的KM打卡"
        left-arrow
        @click-left="$router.replace({ path: '/workSignin' })"
      >
      </van-nav-bar>
    </van-sticky>

    <van-row>
      <van-col span="12">
        <van-cell title="开始日期" :value="listpara.filterData.dateBegin" @click="showcalendar = true" />
        <van-calendar
      v-model="showcalendar"
      :min-date="new Date(2022, 4, 10)"
      @confirm="onConfirm"
    />
      </van-col>
      <van-col span="12">
        <van-cell title="结束日期" :value="listpara.filterData.dateEnd" @click="showcalendarDateEnd = true" />
        <van-calendar
      v-model="showcalendarDateEnd"
      :min-date="new Date(2022, 4, 10)"
      @confirm="onConfirmDateEnd"
    />
      </van-col>
    </van-row>
    <van-collapse v-model="activeNames">
  <van-collapse-item title="忘记打卡" name="1">如果忘记KM打卡,可以到金蝶HR系统补卡。
    <van-tag
    
    color='#9d1d22' 
          round
          size="large"
          @click="gotoshr()"
          >去金蝶HR系统补卡</van-tag
        >
  </van-collapse-item>

</van-collapse>
   

    
    

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
    <van-cell v-for="(item, index) in list" :key="index" class="line" center >
      <van-row :class="getitemClass(item)" >
        <van-col span="12">{{ formatDateDay(item.date) }} <span style="color: #9d1d22;">{{ item.isWeekend?'周末':'' }}</span></van-col>
        <van-col span="12">{{ formatTimeLength(item.describe) }} </van-col>
        <van-col span="12"
          >早 <span v-if="item.beginTime">{{ formatDateMinite(item.beginTime) }}</span>
          <span v-else>-</span>
        </van-col>
        <van-col span="12"
          >晚 <span v-if="item.endTime">{{ formatDateMinite(item.endTime) }}</span>
          <span v-else>-</span>
        </van-col>
      
      </van-row>
    </van-cell>
    </van-list>
  </div>
</template>
  
<script>
import { DayList } from "@/api/workSignin";

export default {
  data() {
    return {
      activeNames:[],
      list: [],
      showcalendar: false,
      showcalendarDateEnd:false,
      loading: false,
      finished: false,
      listpara: {
        filterData: {
          signInStatus: 0,
          dateBegin:"",
          dateEnd:"",
        },
        sorter: {},
        current: 1,
        pageSize: 20,
        fromType: 1,
      },
    };
  },

  computed: {},
  created() {
    this.onSearch();
  },
  methods: {
    async onConfirm(date) {
      this.showcalendar = false;
      this.listpara.filterData.dateBegin = this.formatDate(date);
      this.onSearch();
    },
    async onConfirmDateEnd(date) {
      this.showcalendarDateEnd = false;
      this.listpara.filterData.dateEnd = this.formatDate(date);
      this.onSearch();
    },
    
    formatTimeLength(dateString){
        if(dateString.toString().indexOf('.')>0)
        {
            return dateString.substr(0,3)
        }
        return dateString
    },
    formatDateDay(dateString){
        return dateString.replace(" 00:00:00", "").replace(" 0:00:00","")
    },
    formatDateMinite(dateString){
        return dateString.substr(11,5)
    },
    getitemClass(item){
        if(item.describe.indexOf("班")>0)
        {
            return "itemwarning"
        }
        if(item.isWeekend)
        {
            return"";
        }
        else if(item.describe.indexOf("卡")>0)
        {
            return "itemwarning"
        }
        return "";
    },

    
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    gotoshr(){
        let link = document.createElement("a");
            link.style.display = "none";
            link.href ="http://182.48.111.205:9898/shr";
            link.rel = "noreferrer";
            link.click();
    },
    onSearch() {
      this.finished = false;
      this.list = [];
      this.listpara.current = 1;
      this.listpara.pageSize = 15;
      this.onLoad();
    },
    async onLoad() {
      this.loading = true;
      let result = await DayList({ ...this.listpara });
      let { data } = result.data;

      this.list.push(...data);
      this.loading = false;
      if (this.listpara.current * this.listpara.pageSize < result.data.total) {
        this.listpara.current++;
      } else {
        this.finished = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.line{
    line-height: 35px;
    padding: 3px;
}
.itemwarning{
    border-radius: 10px 10px 10px 10px;
    border: 2px solid #9d1d22;
}
</style>